/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

const Offer = ({ data }) => {
  const offer = data.offer;

  return (
    <div
      id="Leistungen"
      sx={{
        px: 4,
        py: [9, 8, 8],
      }}
    >
      <div sx={{ maxWidth: "1000px", margin: "0 auto" }}>
        <h2
          sx={{
            variant: "hs.h2",
            mb: 3,
          }}
        >
          {offer.title}
        </h2>
        <h3
          sx={{
            variant: "hs.h3",
            fontSize: [3, 7, 7],
            mb: [10, 7, 7],
          }}
        >
          {offer.subtitle}
        </h3>

        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {offer.services.map((el, index) => {
            return (
              <React.Fragment key={index}>
                <div
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="700"   
                  sx={{
                    p: [1, 5, 5],
                    width: ["100%", "100%", "48%"],

                    borderStyle: "solid",
                    borderWidth: 7,
                    borderColor: "one",
                    mb: [4, null, null],
                    "&:nth-of-type(1)": {
                      mb: 4,
                    },
                    "&:nth-of-type(2)": {
                      mb: 4,
                    },
                  }}
                >
                  <h4
                    sx={{
                      variant: "hs.h4",
                      mb: [2, 6, 6],
                    }}
                  >
                    {el.service.title}
                  </h4>
                  <ul sx={{ m: 0, p: 0 }}>
                    {el.service.features.map((el, index) => {
                      return (
                        <React.Fragment key={index}>
                          <li
                            sx={{
                              listStyleType: "none",
                              display: "flex",
                            }}
                          >
                            <div
                              sx={{
                                color: "one",
                                fontSize: 9,
                                fontWeight: "bold",
                                mr: "0.5rem",
                              }}
                            >
                              ✓
                            </div>
                            <div sx={{ display: "flex", alignItems: "center" }}>
                              <p
                                sx={{
                                  variant: "text.body",
                                  m: 0,
                                }}
                              >
                                {el}
                              </p>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Offer;
