/** @jsx jsx */
import { jsx } from "theme-ui";

const Benefits = ({ data }) => {
  const benefits = data.benefits;

  return (
    <div sx={{ px: 4, py: [9, 8, 8] }}>
      <div id="Vorteile" sx={{ maxWidth: "1000px", margin: "0 auto" }}>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2
            sx={{
              variant: "hs.h2",
              mb: 3,
            }}
          >
            {benefits.title}
          </h2>
          <h3 sx={{ variant: "hs.h3", fontSize: [3, 7, 7], mb: [10, 7, 7] }}>
            {benefits.subtitle}
          </h3>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            justifyContent: "space-between",
          }}
        >
          {benefits.blurbs.map((item, index) => (
            <div
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="700"
              key={index}
              sx={{
                width: ["100%", "100%", "33%"],
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: {
                  mb: [10, 10, null],
                },
                "&:last-of-type": {
                  p: {
                    mb: [0, 0, null],
                  },
                },
              }}
            >
              <img
                sx={{ bg: "one", p: 4, mb: 4 }}
                src={item.imageObject.image.publicURL}
                alt={item.imageObject.alt}
                title={item.imageObject.title}
              />
              <h4
                sx={{
                  variant: "hs.h4",
                  margin: 0,
                  mb: 3,
                  color: "white",
                }}
              >
                {item.title}
              </h4>
              <p
                sx={{
                  variant: "text.body",
                  m: "0px",
                  textAlign: "center",
                  px: 3,
                }}
              >
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benefits;
