/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const PainAndPleasure = ({ data }) => {
  const contentPain = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.pain)
    .toString();

  const contentPleasure = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.pleasure)
    .toString();

  return (
    <React.Fragment>
      <section sx={{ bg: "one", px: 4, py: [9, 8, 8] }}>
        <div
          dangerouslySetInnerHTML={{ __html: contentPain }}
          sx={{
            maxWidth: "1000px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            h2: { variant: "hs.h2", fontSize: [7, 9, 9], mb: [10, 7, 7] },
            h3: { variant: "hs.h3", fontSize: [3, 7, 7], mb: [10, 7, 7] },
            p: {
              variant: "text.body",
              m: "0",
            },
          }}
        />
      </section>
      <section sx={{ px: 4, py: [9, 8, 8], bg: "background2" }}>
        <div
          dangerouslySetInnerHTML={{ __html: contentPleasure }}
          sx={{
            maxWidth: "1000px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            h2: { variant: "hs.h2", fontSize: [7, 9, 9], mb: [10, 7, 7] },
            h3: { variant: "hs.h3", fontSize: [3, 7, 7], mb: [10, 7, 7] },
            p: {
              variant: "text.body",
              m: "0",
            },
          }}
        />
      </section>
    </React.Fragment>
  );
};
export default PainAndPleasure;
