/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import videoMp4 from "../../static/img/agentur-ignis-video.mp4";
//import videoOgv from "../../static/img/agentur-ignis-video.ogv";
import videoWebm from "../../static/img/agentur-ignis-video.webm";

const Herosection = ({ data }) => {
  const herosection = data.herosection;
  const cta = data.cta;
  return (
    <React.Fragment>
      <div>
        <video
          sx={{
            width: "100%",
          }}
          autoPlay
          playsInline
          muted
          loop
          id="agentur-ignis-video"
        >
          <source src={videoMp4} type="video/mp4" />
          {/* <source src={videoOgv} type="video/ogg" /> */}
          <source src={videoWebm} type="video/webm" />
        </video>
      </div>
      <div sx={{ px: 4, display: ["block", "block", "block"] }}>
        <div
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: ["center", "center", "flex-start"],
            pt: 4,
            maxWidth: "1000px",
            margin: "0 auto",
          }}
        >
          <h1
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="700"
            sx={{
              variant: "hs.h2",
              fontSize: [7, 9, 9],
              mb: 4,
              textAlign: ["center", "center", "start"],
            }}
          >
            {herosection.title}
          </h1>
          <h2
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="700"
            sx={{
              variant: "hs.h3",
              fontSize: [3, 7, 7],
              mb: 4,
              textAlign: ["center", "center", "start"],
            }}
          >
            {herosection.subtitle}
          </h2>
          <a
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="700"
            href="/#Kontaktformular"
            sx={{ variant: "buttons.cta", alignSelf: "center", py: ["0.5rem", "0.5rem", "0.5rem"], px: ["0.5rem", "1rem", "1rem"],  }}
          >
            {cta}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Herosection;
