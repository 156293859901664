/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { IndexPageTemplate } from "../templates/index-page";
import Layout from "../components/Layout";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <IndexPageTemplate data={data} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        cta
        herosection {
          title
          subtitle
          imageObject {
            title
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 5000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        benefits {
          title
          subtitle
          blurbs {
            title
            description
            imageObject {
              title
              alt
              image {
                publicURL
              }
            }
          }
        }
        pain
        pleasure
        offer {
          title
          subtitle
          services {
            service {
              title
              features
            }
          }
        }
        team {
          title
          subtitle
          teamMember {
            fullName
            position
            description
            email
            phone
            imageObject {
              title
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 5000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        contact {
          title
          subtitle
          phone
          email
        }
      }
    }
  }
`;
