/** @jsx jsx */
import { jsx } from 'theme-ui'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ title, alt, childImageSharp, image, sxProp }) => {

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        sx={sxProp}
        fluid={image.childImageSharp.fluid}
        alt={alt}
        title={title}
      />
    )
  }

  if (!!childImageSharp) {
    return (
      <Img
        fluid={childImageSharp.fluid}
        alt={alt}
        title={title}
      />
    )
  }

  if (!!image && typeof image === 'string') {
    return <img src={image} alt={alt} title={title} />
  }

  return null
}

export default PreviewCompatibleImage
