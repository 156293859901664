/** @jsx jsx */
import { jsx } from "theme-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import Form from "./Form";

const Contact = ({ data }) => {
  const contact = data.contact;
  const phoneWithoutBlanks = contact.phone.replace(/\s+/g, "");

  return (
    <div
      id="Kontakt"
      sx={{
        bg: "one",
        px: 4,
        py: [9, 8, 8],
      }}
    >
      <div sx={{ maxWidth: "1000px", margin: "0 auto" }}>
        <h2
          sx={{
            variant: "hs.h2",
            mb: 3,
          }}
        >
          {contact.title}
        </h2>
        <h3 sx={{ variant: "hs.h3", fontSize: [3, 7, 7], mb: [10, 7, 7] }}>
          {contact.subtitle}
        </h3>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row", "row"],
            justifyContent: "space-between",
          }}
        >
          <div sx={{ mr: [null, 3, 3] }}>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row", "row"],
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                sx={{ fontSize: 10, mr: [0, 2, 2], mb: [2, 0, 0] }}
              />
              <a
                href={`mailto:${contact.email}`}
                sx={{
                  variant: "text.body",
                  textDecoration: "none",
                  color: "text",
                  fontWeight: "telAndMail",
                }}
              >
                {contact.email}
              </a>
            </div>
          </div>
          <div id="Kontaktformular">
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease-in-out"
              data-sal-duration="700"
              sx={{ backgroundColor: "text", p: [3, 6, 6], mt: [6, 0, 0] }}
            >
              <h4
                sx={{
                  variant: "hs.h2",
                  color: "background",
                  pb: 4,
                  textAlign: "center",
                }}
              >
                Oder per Direktnachricht!
              </h4>
              <div>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
