/** @jsx jsx */
import { jsx } from "theme-ui";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const TeamGrid = ({ data }) => {
  const team = data.team;

  team.teamMember.forEach((el) => {
    el.description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(el.description)
      .toString();
  });

  return (
    <div id="team" sx={{ pt: 6, px: 4, py: [9, 8, 8] }}>
      <div sx={{ maxWidth: "1000px", margin: "0 auto" }}>
        <div sx={{ display: "flex", flexDirection: "column" }}>
          <h2
            sx={{
              variant: "hs.h2",
              mb: 3,
            }}
          >
            {team.title}
          </h2>
          <h3
            sx={{
              variant: "hs.h3",
              fontSize: [3, 7, 7],
              mb: [10, 7, 7],
            }}
          >
            {team.subtitle}
          </h3>
        </div>
        <div>
          {team.teamMember.map((el, index) => {
            return (
              <div
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: ["column", "row", "row"],
                  mb: 7,
                  "> div": {
                    pl: [0, 3, 3],
                    pr: 0,
                    pt: [3, 0, 0],
                  },
                  "&:nth-of-type(even)": {
                    display: "flex",
                    flexDirection: ["column", "row-reverse", "row-reverse"],
                    "> div": {
                      pl: 0,
                      pr: [0, 3, 3],
                    },
                  },
                  "&:last-of-type": {
                    mb: 0,
                  },
                }}
              >
                <PreviewCompatibleImage
                  sxProp={{ width: "100%" }}
                  title={el.imageObject.title}
                  alt={el.imageObject.alt}
                  childImageSharp={el.imageObject.image.childImageSharp}
                  image={el.imageObject.image}
                />
                <div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h4
                      sx={{
                        variant: "hs.h4",
                        fontSize: [5, 8, 8],
                      }}
                    >
                      {el.fullName}
                    </h4>
                    <h5
                      sx={{
                        m: 0,
                        variant: "hs.h5",
                        fontSize: ["1rem", 5, 5],
                        mb: 5,
                      }}
                    >
                      {el.position}
                    </h5>

                    {!!el.description && (
                      <div
                        dangerouslySetInnerHTML={{ __html: el.description }}
                        sx={{
                          p: {
                            variant: "text.body",
                            m: 0,
                            pb: 3,
                          },
                        }}
                      />
                    )}
                  </div>
                  <div
                    sx={{
                      display: "flex",
                      flexDirection: ["column", "row", "row"],
                      justifyContent: "space-around",
                    }}
                  >
                    <a
                      data-sal="zoom-in"
                      data-sal-delay="200"
                      data-sal-easing="ease"
                      data-sal-duration="700"
                      href={`mailto:${el.email}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textDecoration: "none",
                        fontWeight: "semibold",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        sx={{ fontSize: 10, color: "one", m: 2 }}
                      />
                      <span
                        sx={{
                          variant: "text.body",
                          color: "text",
                          fontWeight: "telAndMail",
                        }}
                      >
                        {el.email}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamGrid;
