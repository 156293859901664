/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import Herosection from "../components/Herosection";
import Benefits from "../components/Benefits";
import PainAndPleasure from "../components/PainAndPleasure";
import Offer from "../components/Offer";
import Team from "../components/Team";
import Contact from "../components/Contact";

import useSiteMetadata from "../components/SiteMetadata";
import { withPrefix } from "gatsby";
import { Helmet } from "react-helmet";

export const IndexPageTemplate = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const { title, description } = useSiteMetadata();

  return (
    <React.Fragment>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/agentur-ignis-titelbild.jpg`}
        />
      </Helmet>
      <Herosection
        data={{
          herosection: { ...frontmatter.herosection },
          cta: frontmatter.cta,
        }}
      />
      <Benefits data={{ benefits: { ...frontmatter.benefits } }} />
      <PainAndPleasure
        data={{ pain: frontmatter.pain, pleasure: frontmatter.pleasure }}
      />
      <Offer data={{ offer: { ...frontmatter.offer } }} />
      <Team data={{ team: { ...frontmatter.team } }} />
      <Contact data={{ contact: { ...frontmatter.contact } }} />
    </React.Fragment>
  );
};
